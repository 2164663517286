<template>
    <div class="footer">
        <div class="copyright">
            <div class="icp" @click="jump">闽ICP备2022010578号-1</div>© 2022 Followind Studio All
            Right Seserved.
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            copyRightColor: {
                color: '#000'
            }
        }
    },
    methods: {
        jump() {
            window.open('https://beian.miit.gov.cn', '_blank')
        }
    },
    mounted() {
        let pageIndex = this.$route.fullPath;
        if(pageIndex === '/studio' ) {
        this.copyRightColor.color = '#fff'
        }
    }
}
</script>

<style scoped lang="less">
// 常规
.footer {
    padding: 0.3rem 0.2rem;

    .copyright {

        font-size: 14px;
        line-height: 30px;
        font-weight: 700;

        .icp {
            font-weight: 400;
        }

    }
}

/* 宽度大于1900*/
@media only screen and (min-width:1900px) {

    .copyright {
        font-size: 16px;
        line-height: 30px;

    }

}

/* 宽度大于1100并且小于1400 */
@media only screen and (min-width:1100px) and (max-width:1399px) {
.footer {
    padding: 0.4rem 0.2rem;

    .copyright {
        font-size: 12px;
        line-height: 26px;
        font-weight: 700;

        .icp {
        font-weight: 400;
        cursor:pointer;
        }

    }
}
}

/* 宽度大于768并且小于1100 */
@media only screen and (min-width:768px) and (max-width:1099px) {
.footer {
    padding: 0.4rem 0.2rem;

    .copyright {
        font-size: 10px;
        line-height: 22px;
        font-weight: 700;

        .icp {
        font-weight: 400;
        cursor:pointer;
        }

    }
}
}

/* 宽度小于768 */
@media only screen and (max-width:768px) {
    .footer {
        padding: 50px 0;

        .copyright {
            text-align: center;
            font-size: 12px;
            font-weight: 700;
            line-height: 20px;

            .icp {
                font-weight: 400;
                cursor:pointer;
            }
        }

    }

}
</style>>
